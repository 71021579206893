import axios from 'axios';
import { BASE_API } from '@/api/v1'

class LoginController {
    async login(userData) {
        try {
            const response = await axios.post(BASE_API + 'login', userData);
            const token = response.data.access_token;
            const user = response.data.user;
            if (user.status === 0)
                throw new Error('Your account has been blocked');
            if (!user.email_verified_at)
                throw new Error('Account must be verified');
            localStorage.setItem('access_token', token, { expires: 1 });
            return response;
        } catch (error) {
            throw error;
        }
    }
}

export default LoginController;